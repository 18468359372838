import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      auth:true
    }
  },
  {
    path: '/projects',
    name: 'Project',
    component: () => import('../views/Project.vue'),
    meta: {
      auth:true
    }
  },
  {
    path: '/builds/:id',
    name: 'Build',
    component: () => import('../views/Build.vue'),
    meta: {
      auth:true
    }
  },
  {
    path: '/settings/:id',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
    meta: {
      auth:true
    }
  },{
    path: '/settings/',
    name: 'BuilderSettings',
    component: () => import('../views/BuilderSettings.vue'),
    meta: {
      auth:true
    }
  },
  {
    path: '/servers/',
    name: 'Server',
    component: () => import('../views/Server.vue'),
    meta: {
      auth:true
    }
  },
  {
    path: '/logs/:id',
    name: 'Logs',
    component: () => import('../views/Progress.vue'),
    meta: {
      auth:true
    }
  },
  {
    path: '/release/:id',
    name: 'Release',
    component: () => import('../views/Release.vue'),
    meta: {
      auth:true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
