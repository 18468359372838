import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import socketio from 'socket.io-client';

import loginService from "node-back-client/src/services/login-service";
import mitt from 'mitt'

const emitter = mitt()
const app = createApp(App);
const SocketInstance = socketio(`${process.env.BASE_URL}`, JSON.parse(process.env.VUE_APP_SOCKET_CONFIG));
app.config.globalProperties.socket = SocketInstance;
app.config.globalProperties.emitter = emitter;


loginService.init(router,"dff44362-d79c-4f5c-a038-dab88897e061");
app.use(router);
app.mount('#app');

