<template>
  <nav class="navbar navbar-expand-md navbar-light bg-light mb-5">
    <div class="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2">
      <ul class="navbar-nav me-auto">
        <li class="nav-item active">
          <router-link to="/" class="btn">Home</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/projects" class="btn">Projects</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/servers" class="btn">Servers</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/settings" class="btn">Settings</router-link>
        </li>
      </ul>
      <display-picture :is-left="true" class="me-3"></display-picture>
    </div>

  </nav>
  <router-view/>

  </template>

  <script>
  import DisplayPicture from 'node-back-client/src/components/DisplayPicture'

  export default {
    name: 'Header',
    components: {
      DisplayPicture
    }
  }

  </script>