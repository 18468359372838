<template>
  <Header></Header>
<!--  <toast class="position-fixed bottom-0 end-0 p-3" ></toast>-->
  <ModalHandler></ModalHandler>
  <InsertModalHandler></InsertModalHandler>
<!--  <toast class="position-fixed top-0 end-0 p-3" is-build="true"></toast>-->
  <toast-handler></toast-handler>
</template>

<script>
import Header from "./components/Header";
import ToastHandler from 'toast/src/components/ToastHandler'
import ModalHandler from "modal/src/components/ModalHandler";
import InsertModalHandler from "modal/src/components/InsertModalHandler";
import toastService from 'toast/src/services/toast';
export default {
  name:"App",
  components:{
    InsertModalHandler,
    ModalHandler,
    ToastHandler,
    Header,
  },
  created() {

    this.socket.on('notify',dat => {
      toastService.Notify(dat);
    });
  }
}
</script>
